import { tTransport } from "types/transport";
import { projectKbn, projectStatus } from "const/project";
import { tAddress, tCompany, tProjectKind } from "./mst";
export type tProject = {
  id: number; // ID
  domain: string; // ドメイン
  created_at: string; // created_at
  updated_at: string; // updated_at
  deleted_at?: string; // deleted_at

  status: number; // ステータス
  kind: number; // 種別
  c_id: number; // 会社マスタID
  p_id?: number; // 担当者ID
  load_date: string; // 積：日時
  load_a_id: number; // 積：住所ID
  load_prefectures: string; // 積：都道府県
  load_city: string; // 積：市区町村
  load_street: string; // 積：番地
  load_building?: string; // 積：建屋
  load_post_number: string; // 積：郵便番号
  load_tell: string; // 積：電話番号
  load_fax?: string; // 積：FAX
  waypoints: any[]; // 経由地点
  unload_date: string; // 卸：日時
  unload_a_id: number; // 卸：住所ID
  unload_prefectures: string; // 卸：都道府県
  unload_city: string; // 卸：市区町村
  unload_street: string; // 卸：番地
  unload_building?: string; // 卸：建屋
  unload_post_number: string; // 卸：郵便番号
  unload_tell: string; // 卸：電話番号
  unload_fax?: string; // 卸：FAX
  vt_id: number; // 車種ID（車種マスタID）
  cars: number; // 台数
  weight: number; // 重量
  l_id: number; // 荷物種類ID（荷物種類マスタID）
  l_volume: number; // 数量
  memo: string; // 荷物メモ
  price_unit_reference: number; // 参考単価
  price_unit: number; // 単価
  price: number; // 金額（税抜）
  tax_id: string; // 消費税マスタID
  price_tax: number; // 金額（税込）
  internal_memo: string; // 内部メモ

  // 以下、新たに追加
  transports?: tTransport[]; // 新たに追加
  company?: tCompany; // 新たに追加
  load_address?: tAddress; // 新たに追加
  unload_address?: tAddress; // 新たに追加
  project_kind?: tProjectKind; // 新たに追加

  // 2024/08/15追加
  in_charge: number; // 担当者ID
  price_others: tPriceOther[]; // その他の金額
};

/**
 * 初期値を生成する
 * @returns
 */
export const createEmptyProject = (): tProject => {
  return {
    id: 0,
    domain: "",
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    deleted_at: undefined,

    status: 0,
    kind: 0,
    c_id: 0,
    p_id: 0,
    load_date: new Date().toISOString(),
    load_a_id: 0,
    load_prefectures: "",
    load_city: "",
    load_street: "",
    load_building: undefined,
    load_post_number: "",
    load_tell: "",
    load_fax: undefined,
    waypoints: [],
    unload_date: new Date().toISOString(),
    unload_a_id: 0,
    unload_prefectures: "",
    unload_city: "",
    unload_street: "",
    unload_building: undefined,
    unload_post_number: "",
    unload_tell: "",
    unload_fax: undefined,
    vt_id: 0,
    cars: 1,
    weight: 0.0,
    l_id: 0,
    l_volume: 0,
    memo: "",
    price_unit_reference: 0,
    price_unit: 0,
    price: 0,
    tax_id: "",
    price_tax: 0,
    internal_memo: "",
    in_charge: 0,
    price_others: [],
    /*
    transports: [],
    company: {},
    load_address: {},
    unload_address: {},
    project_kind: {},
    */
  };
};

export default createEmptyProject;

export type tWaypoint = {
  kbn: number; // 種別
  datetime: string; // 日時
  a_id?: number; // 住所ID
  a_name: string; // 住所名
  prefectures: string; // 都道府県
  city: string; // 市区町村
  street: string; // 番地
  building?: string; // 建屋
  post_number: string; // 郵便番号
  tell: string; // 電話番号
  fax?: string; // FAX
  memo?: string; // メモ
};

export type tWaypoints = tWaypoint[];
export const initialWaypoint: tWaypoint = {
  kbn: projectKbn.load.id,
  datetime: new Date().toISOString(),
  a_id: undefined,
  a_name: "",
  prefectures: "",
  city: "",
  street: "",
  building: "",
  post_number: "",
  tell: "",
  fax: "",
  memo: "",
};

export type tProjectCard = {
  id: number;
  company_name: string;
  company_abbreviation: string;

  load_date: string;
  load_id: number;
  load_name: string;
  load_name_mst: string;
  load_abbreviation_mst: string;

  unload_date: string;
  unload_id: number;
  unload_name: string;
  unload_name_mst: string;
  unload_abbreviation_mst: string;

  status: number;
  kind: number;
  kind_name: string;
};

export type tProjectCardList = tProjectCard[];

export type tPriceOther = { label: string; value: number }; // その他の金額
