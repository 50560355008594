import { useContext } from "react";
import { useTheme } from "Theme";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import { Link } from "react-router-dom";
import { Paper } from "@mui/material";
import { typeMenu, typeSubMenu } from "const/menu";
import { useLocation } from "react-router-dom";

const styles = {
  headerMenu: {
    width: "130px",
    maxHeight: "100%",
    backgroundColor: useTheme.palette.primary.main,
    color: useTheme.palette.primary.contrastText,
  },
};

/**
 *
 * @returns
 */
export default function Main({ menu }: { menu: typeMenu }): React.ReactElement {
  const location = useLocation();

  return (
    <div style={styles.headerMenu}>
      <List sx={styles.headerMenu}>
        {menu.subMenus.map((subMenu: typeSubMenu) => {
          if (subMenu.flg) {
            const isSubPage = location.pathname.startsWith(`${subMenu.path}`);
            return (
              <ListItemButton
                key={`${subMenu.path}`}
                component={Link}
                to={`${window.location.origin}${subMenu.path}`}
                target={subMenu.aTab ? "_blank" : ""}
              >
                <ListItemText
                  primary={subMenu.label}
                  primaryTypographyProps={{
                    color: useTheme.palette.text.secondary,
                    fontSize: "1rem",
                    borderBottom: isSubPage ? "2px solid" : "none",
                  }}
                />
              </ListItemButton>
            );
          }
        })}
      </List>
    </div>
  );
}
