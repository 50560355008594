import { List, ListItem } from "types";

export const projectKbn = {
  load: {
    id: 1,
    label: "積地",
  },
  unload: {
    id: 2,
    label: "卸地",
  },
};

export const projectStatusMitsumori: ListItem = { id: 0, label: "見積中" };
export const projectStatusZyutyu: ListItem = { id: 10, label: "受注済" };
export const projectStatusTransport: ListItem = { id: 50, label: "運送中" };
export const projectStatusUnclaimed: ListItem = { id: 70, label: "未請求" };
export const projectStatusInvoice: ListItem = { id: 90, label: "請求済" };
export const projectStatusLost: ListItem = { id: 91, label: "失注" };

export const projectStatus: List = [
  projectStatusMitsumori,
  projectStatusZyutyu,
  projectStatusTransport,
  projectStatusUnclaimed,
  // 90オーバーで完了
  projectStatusInvoice,
  projectStatusLost,
];
