import { APP_ENV } from "functions/env";

export function Console_log(label: String, data: any): void {
  if (APP_ENV() != "production") {
    console.log(label);
    console.log(data);
  }
}

export function getGridSize(size: Record<string, number> | null | undefined) {
  let xs = 12;
  let lg = 2;
  let xl = 2;
  if (size?.xs) {
    xs = size.xs;
  }
  if (size?.lg) {
    lg = size.lg;
  }
  if (size?.xl) {
    xl = size.xl;
  }
  return [xs, lg, xl];
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function toHalfWidth(str: string): string {
  return str
    .replace(/[！-～]/g, (s: string) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    })
    .replace(/　/g, " ")
    .replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s: string) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    });
}

// 3桁区切りにする関数
export const formatNumber = (numStr: string) => {
  const parts = numStr.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

// 文字列を指定位置
export function truncateString(str: string, maxLength: number): string {
  if (str.length <= maxLength) {
    return str;
  }
  return str.slice(0, maxLength) + "...";
}
