import React from "react";
import { SelectChangeEvent } from "@mui/material";
import { GridItemNumberField, GridItemTextField } from "components/GridItem";
import { handleChangeValues } from "functions/handles";
import { List } from "types/index";
import { GridItemSelect } from "atoms/Select";
import { GridItemCheckbox } from "components/GridItem";
import { DateInput } from "atoms/DateInput";

interface ItemFieldProps {
  label: string;
  name?: string;
  value?: any;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  size?: Record<string, number> | null;

  // Number用
  flgFloat?: boolean;

  // Select用
  id?: string;
  labelId?: string;
  selectedValue?: number;
  items?: List;
}

export const ItemTextFiled = ({
  label,
  name = "",
  value = "",
  setValues,
  size,
}: ItemFieldProps) => (
  <GridItemTextField
    label={label}
    value={value}
    name={name}
    handleChangeValues={(e: React.ChangeEvent<HTMLInputElement>) =>
      handleChangeValues(e.target.value, e.target.name, setValues)
    }
    size={size}
  />
);

export const ItemNumberField = ({
  label,
  name = "",
  value = "",
  setValues,
  flgFloat = false,
}: ItemFieldProps) => (
  <GridItemNumberField
    label={label}
    value={value}
    name={name}
    /*
      handleChangeValues={(e: React.ChangeEvent<HTMLInputElement>) => {
        handleChangeValues(e.target.value, e.target.name, setValues);
      }}
        */
    setValues={setValues}
    flgFloat={flgFloat}
  />
);

export const ItemSelect = ({
  labelId = "",
  id = "",
  label,
  selectedValue = 0,
  items = [],
  setValues,
  size = null,
}: ItemFieldProps) => {
  return (
    <GridItemSelect
      labelId={labelId}
      id={id}
      label={label}
      selectedValue={selectedValue}
      items={items}
      size={size}
      handleChangeValues={(
        e: SelectChangeEvent<number | string>,
        child: React.ReactNode
      ) => {
        handleChangeValues(e.target.value, id, setValues);
      }}
    />
  );
};

export const ItemCheckbox = ({
  labelId = "",
  name = "",
  label,
  value = false,
  setValues,
  size = null,
}: ItemFieldProps) => {
  return (
    <GridItemCheckbox
      labelId={labelId}
      id={name}
      name={name}
      label={label}
      value={value}
      size={size}
      handleChangeValues={(e: React.ChangeEvent<HTMLInputElement>) => {
        handleChangeValues(e.target.checked, name, setValues);
      }}
    />
  );
};
