import React from "react";
import { Grid, Typography } from "@mui/material";
import { SmallTextField } from "atoms/TextField";
import {
  MathDateTime,
  changeFromDatetimeToInputTypeDate,
} from "functions/time";
import { DateInput } from "atoms/DateInput";

/**
 * 検索などで使う項目
 */

/**
 * 初回積日時範囲
 * @param values
 * @param setValues
 * @returns
 */
export function RangeLoadDatetime({
  values,
  setValues,
}: {
  values: any;
  setValues: React.Dispatch<React.SetStateAction<any>>;
}) {
  return (
    <>
      <Typography variant="h6">積日範囲</Typography>
      <Grid container spacing={1}>
        <Grid item>
          <DateInput
            type="date"
            value={values.load_date_from || ""}
            name="load_date_from"
            onDateChange={(newDate: string) => {
              const Add7Day = MathDateTime(
                new Date(newDate),
                [0, 0, 7, 0, 0, 0]
              );
              setValues((prevValues: any) => ({
                ...prevValues,
                load_date_from: newDate,
                load_date_to: changeFromDatetimeToInputTypeDate(Add7Day),
              }));
            }}
          />
        </Grid>
        <Grid item>
          <Typography sx={{ textAlign: "center" }}>〜</Typography>
        </Grid>
        <Grid item>
          <DateInput
            type="date"
            value={values.load_date_to || ""}
            name="load_date_to"
            onDateChange={(newDate: string) => {
              setValues({ ...values, load_date_to: newDate });
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

/**
 * 最終卸日時範囲
 * @param values
 * @param setValues
 * @returns
 */
export function RangeUnloadDatetime({
  values,
  setValues,
}: {
  values: any;
  setValues: React.Dispatch<React.SetStateAction<any>>;
}) {
  return (
    <>
      <Typography variant="h6">卸日範囲</Typography>
      <Grid container spacing={1}>
        <Grid item>
          <DateInput
            type="date"
            value={values.unload_date_from || ""}
            name="unload_date_from"
            onDateChange={(newDate: string) => {
              const Add7Day = MathDateTime(
                new Date(newDate),
                [0, 0, 7, 0, 0, 0]
              );
              setValues((prevValues: any) => ({
                ...prevValues,
                unload_date_from: newDate,
                unload_date_to: changeFromDatetimeToInputTypeDate(Add7Day),
              }));
            }}
          />
        </Grid>
        <Grid item>
          <Typography sx={{ textAlign: "center" }}>〜</Typography>
        </Grid>
        <Grid item>
          <DateInput
            type="date"
            value={values.unload_date_to || ""}
            name="unload_date_to"
            onDateChange={(newDate: string) => {
              setValues({ ...values, unload_date_to: newDate });
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
