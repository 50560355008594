import React, { act, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

interface MainProps {
  title: string;
  content: React.ReactNode;
  actions?: React.ReactNode;
  open: boolean;
}
export function Main({ title, content, actions, open }: MainProps) {
  return (
    <Dialog open={open} maxWidth="lg" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
}

// 閉じる処理
/*
const handleClose = (
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  callback?: () => void
) => {
  setOpen(false);
  if (!callback) return;
  callback();
};
*/
