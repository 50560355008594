import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import { dark } from "@mui/material/styles/createPalette";

export const palette = {
  primary: "#ed7700",
  secondary: "#73a7d1",
  black: "#222222",
  white: "#FFFFFF",
  glay: "#f5f5f5",
  glayDark: "#a9a9a9",
};

// 全体のテーマ設定
export const theme = createTheme({
  palette: {
    primary: {
      main: palette.primary,
      contrastText: palette.white,
    },
    secondary: {
      main: palette.secondary,
      contrastText: palette.white,
    },
    text: {
      primary: palette.black,
      secondary: palette.white,
    },
    background: {
      default: palette.white,
    },
  },
  typography: {
    h1: {
      fontSize: "2.0rem",
    },
    body1: {
      fontSize: "1.0rem",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: "40px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          background: palette.white,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: palette.primary,
          "&.Mui-checked": {
            color: palette.primary,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputLabel-root": {
            color: palette.primary,
          },
          /*
          "& .MuiInputBase-root": {
            color: color.primary,
          },
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: color.primary,
          },
          */
          "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: palette.primary,
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: palette.primary,
            },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
        },
      },
    },
    // ここに main のスタイル設定を追加します
    MuiCssBaseline: {
      styleOverrides: {
        main: {
          height: "calc(100vh - 40px)",
          flexGrow: 1 /* 残りの横幅を均等に分配する */,
          flexBasis: 0,
          minWidth: 0 /* 必要に応じて、文字がはみ出ないようにするために設定 */,
          padding: "4px",
          overflow: "scroll",
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          "& .MuiTableCell-root": {
            border: `1px solid ${palette.black}`,
            padding: "4px",
          },
          "& .MuiTableCell-head": {
            textAlign: "center",
            backgroundColor: palette.primary,
            color: palette.white,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: palette.primary,
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: palette.primary,
            },
        },
      },
    },
  },
});

export let useTheme = theme;

export function ThemeProviderDark({ children }: any) {
  useTheme = theme;
  return <ThemeProvider theme={useTheme}>{children}</ThemeProvider>;
}

export function ThemeProviderCustom({ children }: any) {
  return <ThemeProvider theme={useTheme}>{children}</ThemeProvider>;
}

export const colorSub01 = {
  primary: "#a1cff4",
  secondary: "#FFFFFF",
  background: "#222222",
};
