import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
} from "@mui/material";
import { tLuggage } from "types/mst";
import { getLuggages } from "functions/api/mst";
import { CommonDataContext } from "Context";
import { PrimaryButton } from "atoms/Button";
import PageNation, { handlePageChange } from "components/Pagenation";

export default function Main() {
  const navigate = useNavigate();

  const { loading, vehicleTypes } = useContext(CommonDataContext);

  // 変数名の便宜上複数形を作成
  const [luggages, setLaggages] = useState<tLuggage[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const getSearch = async (page: number) => {
    try {
      // ここにデータ取得処理を記述します
      getLuggages(page)
        .then((res) => {
          if (res.status !== 200) throw new Error("データ取得に失敗しました");
          setLaggages(res.data.data);
          setCurrentPage(res.data.current_page);
          setLastPage(res.data.last_page);
          setTotalPages(res.data.last_page);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getSearch(currentPage);
  }, []);

  if (loading || !vehicleTypes) {
    return <div>Loading...</div>;
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={4} md={4} lg={2}>
            <PrimaryButton
              onClick={() => {
                navigate("/mst/luggage/input");
              }}
              label="新規"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table sx={{ maxWidth: "600px" }}>
            <TableHead>
              <TableRow>
                <TableCell>名前</TableCell>
                <TableCell>単位</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {luggages.map((lagguge) => {
                /* memo：対象車両を登録できるようになればここに
                const vtInfo = vehicleTypes.find(
                  (vt) => vt.id === vehicle.cm_id
                );
                */
                return (
                  <TableRow
                    key={`luggages-${lagguge.id}`}
                    onDoubleClick={() =>
                      navigate(`/mst/luggage/edit/${lagguge.id}`)
                    }
                  >
                    <TableCell>{lagguge.name}</TableCell>
                    <TableCell>{`${lagguge.volume}${lagguge.unit}`}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <PageNation
          totalPages={totalPages}
          currentPage={currentPage}
          handlePageChange={(e: React.ChangeEvent<unknown>, value: number) =>
            getSearch(value)
          }
        />
      </Grid>
    </Grid>
  );
}
