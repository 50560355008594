import {
  useState,
  useRef,
  useCallback,
  FocusEvent,
  KeyboardEvent,
  useEffect,
} from "react";
import { getGridSize } from "functions/index";
import { Grid } from "@mui/material";
import { typeHashValue } from "types";
import { SmallCheckbox } from "atoms/Checkbox";
import { SmallTextField } from "atoms/TextField";
import { toHalfWidth, formatNumber } from "functions/index";
import { DateInput } from "atoms/DateInput";

//////////////////////////////////////// textfield

interface GridItemTextFieldProps {
  name: string;
  label: string;
  value: typeHashValue;
  handleChangeValues: (event: React.ChangeEvent<HTMLInputElement>) => void;
  children?: React.ReactNode;
  size?: Record<string, number> | null;
}
export function GridItemTextField({
  name,
  label,
  value,
  handleChangeValues,
  size = undefined,
}: GridItemTextFieldProps) {
  const [xs, lg, xl] = getGridSize(size);
  return (
    <Grid item xs={xs} lg={lg} xl={xl}>
      <SmallTextField
        label={label}
        props={{
          name: name,
          value: value,
          onChange: handleChangeValues,
        }}
      />
    </Grid>
  );
}

interface GridItemNumberProps {
  name: string;
  label: string;
  value: number;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  callback?: (val: number) => void;
  //handleChangeValues: (event: React.ChangeEvent<HTMLInputElement>) => void;
  children?: React.ReactNode;
  size?: Record<string, number> | null;
  flgFloat?: boolean;
}
export function GridItemNumberField({
  name,
  label,
  value,
  setValues,
  callback,
  size = { xs: 4, lg: 1.5, xl: 1 },
  flgFloat = false,
}: GridItemNumberProps) {
  const [xs, lg, xl] = getGridSize(size);
  const [viewVal, setViewVal] = useState<string>("");

  const changeFormatNumber = (numStr: string) => {
    const valNum = changeNumber(numStr);

    if (callback) {
      callback(Number(valNum));
    } else {
      // 更新
      setValues((prev: any) => {
        return { ...prev, [name]: valNum };
      });
    }

    setViewVal(formatNumber(valNum));
  };

  const changeNumber = (val: string) => {
    // 全角を半角に変換
    val = toHalfWidth(val);

    if (flgFloat) {
      // 数値とドット以外の文字を削除
      val = val.replace(/[^0-9.]/g, "");
      // 小数点が2つ以上ないか確認し、最初の1つだけ残す
      const dotParts = val.split(".");
      if (dotParts.length > 2) {
        val = `${dotParts[0]}.${dotParts.slice(1).join("")}`;
      }
    } else {
      // 数値以外の文字を削除
      val = val.replace(/[^0-9]/g, "");
    }

    // 先頭の不要な0を削除。ただし "0." の場合は残す
    if (val.startsWith("0") && val.length > 1 && val[1] !== ".") {
      val = val.replace(/^0+/, "");
    }

    // 数値型に変換したものを文字列にして再セット
    if (!isNaN(Number(val))) {
      return val;
    } else {
      return "";
    }
  };

  useEffect(() => {
    changeFormatNumber(value.toString());
  }, [value]);

  return (
    <Grid item xs={xs} lg={lg} xl={xl}>
      <SmallTextField
        label={label}
        props={{
          name: name,
          value: viewVal,
          onBlur: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            changeFormatNumber(e.target.value);
          },
          onFocus: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setViewVal(changeNumber(e.target.value));
          },
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            setViewVal(e.target.value);
          },
          onKeyPress: (e: KeyboardEvent<HTMLInputElement>) => {
            const char = e.key;
            if (char === "Enter") {
              const temp = e as unknown as FocusEvent<
                HTMLInputElement | HTMLTextAreaElement
              >;
              // フォーカスを外す
              temp.target.blur();
            }
          },
        }}
      />
    </Grid>
  );
}

interface GridItemDateFieldProps {
  name: string;
  label: string;
  value: string;
  handleChangeValues: (value: string) => void;
  size?: Record<string, number> | null;
}
export function GridItemDateField({
  name,
  label,
  value,
  handleChangeValues,
  size = undefined,
}: GridItemDateFieldProps) {
  const [xs, lg, xl] = getGridSize(size);

  const handleDateChange = (newDate: string) => {
    console.log("newDate", newDate);
    // 入力日の23:59:59に設定
    const inputDateTime = new Date(newDate + "T23:59:59");
    if (inputDateTime.toString() === "Invalid Date") {
      return;
    }
    handleChangeValues(newDate);
  };

  return (
    <Grid item xs={xs} lg={lg} xl={xl}>
      <DateInput
        type="date"
        label={label}
        value={value as string}
        name={name}
        onDateChange={handleChangeValues}
      />
    </Grid>
  );
}

export function GridItemTextFieldCustom({
  children,
  size,
}: {
  children: React.ReactNode;
  size?: Record<string, number> | null;
}) {
  const [xs, lg, xl] = getGridSize(size);
  return (
    <Grid item xs={xs} lg={lg} xl={xl}>
      {children}
    </Grid>
  );
}

//////////////////////////////////////// checkbox
interface GridItemCheckboxProps {
  labelId?: string;
  id: string;
  name: string;
  label: string;
  value: boolean;
  handleChangeValues: (event: React.ChangeEvent<HTMLInputElement>) => void;
  children?: React.ReactNode;
  size?: Record<string, number> | null;
}
export function GridItemCheckbox({
  labelId = "",
  id,
  name,
  label,
  value,
  handleChangeValues,
  size,
}: GridItemCheckboxProps) {
  const [xs, lg, xl] = getGridSize(size);
  return (
    <Grid item xs={xs} lg={lg} xl={xl}>
      <SmallCheckbox
        labelId={labelId}
        label={label}
        props={{
          id: id,
          name: name,
          checked: value,
          onChange: handleChangeValues,
        }}
      />
    </Grid>
  );
}
