import React from "react";
import { Typography } from "@mui/material";
import { typeHashValue } from "types/index";
import {
  GridItemTextField,
  GridItemDateField,
  GridItemTextFieldCustom,
} from "components/GridItem";
import { handleChangeValues } from "functions/handles";
import { SmallTextField } from "atoms/TextField";
import { tVehicleType } from "types/mst";

interface ItemFieldProps {
  label: string;
  name: string;
  value: typeHashValue;
  setValues: React.Dispatch<React.SetStateAction<tVehicleType>>;
  size?: Record<string, number> | null;
}
const ItemTextFiled = ({
  label,
  name,
  value,
  setValues,
  size,
}: ItemFieldProps) => (
  <GridItemTextField
    label={label}
    value={value}
    name={name}
    handleChangeValues={(e: React.ChangeEvent<HTMLInputElement>) =>
      handleChangeValues(e.target.value, e.target.name, setValues)
    }
    size={size}
  />
);
const ItemDateFiled = ({
  label,
  name,
  value,
  setValues,
  size,
}: ItemFieldProps) => (
  <GridItemDateField
    label={label}
    value={value as string}
    name={name}
    handleChangeValues={(newDate: string) =>
      handleChangeValues(newDate, name, setValues)
    }
    size={size}
  />
);

interface GridItemFieldProps {
  values: tVehicleType;
  setValues: React.Dispatch<React.SetStateAction<tVehicleType>>;
}
export const Name = ({ values, setValues }: GridItemFieldProps) => (
  <ItemTextFiled
    label="名称"
    name="name"
    value={values.name}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const Description = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTextFieldCustom size={{ xs: 12, lg: 6, xl: 5 }}>
    <Typography variant="h6">説明文</Typography>
    <SmallTextField
      props={{
        multiline: true,
        rows: 4,
        name: "description",
        value: values.description,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
          handleChangeValues(e.target.value, e.target.name, setValues),
      }}
    />
  </GridItemTextFieldCustom>
);
/*
export const CmId = ({ values, setValues }: GridItemFieldProps) => {
  const { loading, carModels } = useContext(CommonDataContext);
  if (loading || !carModels) return <Typography>loading...</Typography>;

  const options = carModels.map((cm) => ({
    id: cm.id,
    label: cm.name,
  }));
  const selectedOption = options.find(
    (option: any) => option.id === values.cm_id
  );

  return (
    <GridItemTextFieldCustom size={{ xs: 12, lg: 3, xl: 2 }}>
      <AutocompleteSelect
        name="cm_id"
        label={"車種"}
        options={options}
        value={selectedOption || null}
        onChange={(e: React.ChangeEvent<{}>, newValue: ListItem) =>
          handleChangeValues(newValue.id, "cm_id", setValues)
        }
      />
    </GridItemTextFieldCustom>
  );
};
*/
