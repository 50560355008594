export type tUser = {
  id: number;
  email: string;
  email_verified_at?: Date;
  password: string;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
  family_name: string;
  given_name: string;
  family_name_kana: string;
  given_name_kana: string;
  birthday: string;
  tell: string;
  post_number: string;
  address: string;
  role_id: number;
  v_id?: string | null;
  hire_date: string;
};

export const initialUser: tUser = {
  id: 0,
  email: "",
  email_verified_at: undefined,
  password: "",
  created_at: new Date(),
  updated_at: new Date(),
  deleted_at: null,
  family_name: "",
  given_name: "",
  family_name_kana: "",
  given_name_kana: "",
  birthday: new Date().toISOString().split("T")[0],
  tell: "",
  post_number: "",
  address: "",
  role_id: 0,
  v_id: undefined,
  hire_date: new Date().toISOString().split("T")[0],
};

export type tRole = {
  id: number;
  name: string;
  admin: boolean;
  project: boolean;
  operation: boolean;
  paperwork: boolean;
  mst: boolean;
};
export const initialRole: tRole = {
  id: 0,
  name: "",
  admin: false,
  project: false,
  operation: false,
  paperwork: false,
  mst: false,
};

export type tCompany = {
  id: number;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
  name: string;
  abbreviation: string;
  office_a_id: number;
  post_number: string;
  tell: string;
  fax: string;
  e_mail: string;
  president_position: string;
  president_name: string;
  flg_use: boolean;
  flg_order: boolean;
  flg_supplier: boolean;
};

export const initialCompany: tCompany = {
  id: 0,
  created_at: new Date(),
  updated_at: new Date(),
  deleted_at: null,
  name: "",
  abbreviation: "",
  office_a_id: 0,
  post_number: "",
  tell: "",
  fax: "",
  e_mail: "",
  president_position: "",
  president_name: "",
  flg_use: false,
  flg_order: false,
  flg_supplier: false,
};

export type tAddress = {
  id: number;
  c_id: number;
  name: string;
  abbreviation: string;
  prefectures: string;
  city: string;
  street: string;
  building?: string;
  post_number: string;
  tell: string;
  fax?: string;
  flg_load: boolean;
  flg_unload: boolean;
  flg_office: boolean;
  internal_memo: string;
};

export const initialAddress: tAddress = {
  id: 0,
  c_id: 0,
  name: "",
  abbreviation: "",
  prefectures: "",
  city: "",
  street: "",
  building: "",
  post_number: "",
  tell: "",
  fax: "",
  flg_load: false,
  flg_unload: false,
  flg_office: false,
  internal_memo: "",
};

export type tCarModel = {
  id: number;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
  name: string;
  car_weight: number;
  max_load_weight: number;
  flg_license_nomal: boolean;
  flg_license_semimediu: boolean;
  flg_license_mediu: boolean;
  flg_license_large: boolean;
  flg_license_speial: boolean;
};

// types/mst.ts

export type tVehicle = {
  id: number;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
  plate: string;
  plate_branch: string;
  plate_kind: string;
  plate_hiragana: string;
  plate_number: string;
  vt_id: number;
  inspection: string;
};
// 初期値の定義
export const initialVehicle: tVehicle = {
  id: 0,
  created_at: new Date(),
  updated_at: new Date(),
  deleted_at: null,
  plate: "",
  plate_branch: "",
  plate_kind: "",
  plate_hiragana: "",
  plate_number: "",
  vt_id: 0,
  inspection: new Date().toDateString().split("T")[0],
};

// 車両種マスタ
export interface tVehicleType {
  id: number;
  name: string;
  description: string;
  cmId?: number | null;
}
export const initialVehicleType: tVehicleType = {
  id: 0,
  name: "",
  description: "",
  cmId: null,
};

// types/mst.ts

export type tTax = {
  id: number;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
  label: string;
  tax: number;
  flg: boolean;
};

// types/mst.ts

export type tLuggage = {
  id: number;
  /*
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
  */
  name: string;
  unit: string;
  volume: number;
  cm_ids: [];
};

export type tProjectKind = {
  id: number;
  domain: string;
  name: string;
  color: string;
  created_at: Date; // or Date if you will convert to Date type in your code
  updated_at: Date; // or Date if you will convert to Date type in your code
  deleted_at?: Date | null; // optional field for soft deletes
};
export const initialProjectKind: tProjectKind = {
  id: 0,
  domain: "",
  name: "その他",
  color: "", // A sample color code
  created_at: new Date(),
  updated_at: new Date(),
  deleted_at: null,
};

export type tPerson = {
  id: number;
  c_id: number;
  family_name: string;
  given_name: string;
  family_name_kana: string;
  given_name_kana: string;
  tell: string;
  email: string;
  post_name: string;
  office_a_id: number;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
};

// 初期値の定義
export const initialPerson: tPerson = {
  id: 0,
  c_id: 0,
  family_name: "",
  given_name: "",
  family_name_kana: "",
  given_name_kana: "",
  tell: "",
  email: "",
  post_name: "",
  office_a_id: 0,
  created_at: new Date(),
  updated_at: new Date(),
  deleted_at: null,
};
