import React from "react";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import Select from "@mui/material/Select";
import { SmallTextField } from "atoms/TextField";
import { Grid, Typography } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { getGridSize } from "functions/index";
import { List } from "types/index";
import { ListItem } from "types/index";
import ClearIcon from "@mui/icons-material/Clear";

interface SelectProps {
  labelId: string;
  id: string;
  items: List;
  selectedValue: number | BigInt;
  onChange: (
    event: SelectChangeEvent<number | string>,
    child: React.ReactNode
  ) => void;
}

export function SmallSelect({
  labelId,
  id,
  items,
  selectedValue,
  onChange,
}: SelectProps) {
  return (
    <FormControl sx={{ minWidth: 120 }} size="small" fullWidth>
      <Select
        labelId={labelId}
        id={id}
        name={id}
        value={Number(selectedValue)}
        onChange={onChange}
        fullWidth={true}
      >
        {items.map((item) => (
          <MenuItem key={item.id.toString()} value={Number(item.id)}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

interface GridItemSelectProps {
  labelId: string;
  id: string;
  label: string;
  selectedValue: number;
  items: List;
  handleChangeValues: (
    event: SelectChangeEvent<number | string>,
    child: React.ReactNode
  ) => void;
  size?: Record<string, number> | null;
}
export function GridItemSelect({
  labelId,
  id,
  label,
  selectedValue,
  items,
  handleChangeValues,
  size = undefined,
}: GridItemSelectProps) {
  const [xs, lg, xl] = getGridSize(size);
  return (
    <Grid item xs={xs} lg={lg} xl={xl}>
      <Typography variant="h6">{label}</Typography>
      <SmallSelect
        labelId={labelId}
        id={id}
        items={items}
        selectedValue={selectedValue}
        onChange={handleChangeValues}
      />
    </Grid>
  );
}

interface AutocompleteSelectProps {
  name: string;
  disableClearable?: boolean;
  readOnly?: boolean;
  label: string;
  options: Record<string, any>[];
  value: Record<string, any> | null;
  onChange: (event: React.ChangeEvent<{}>, newValue: ListItem) => void;
}
export const AutocompleteSelect = ({
  name,
  disableClearable = true,
  readOnly = false,
  label,
  options,
  value,
  onChange,
}: AutocompleteSelectProps) => {
  return (
    <>
      {label && <Typography variant="h6">{label}</Typography>}
      <Autocomplete
        sx={{ padding: 0 }}
        getOptionLabel={(option) =>
          option.label ? String(option.label) : String(option.name)
        }
        value={value}
        onChange={(e, newValue) => onChange(e, newValue)}
        options={options}
        disableClearable={disableClearable}
        readOnly={readOnly}
        renderInput={(params) => (
          <SmallTextField label={undefined} props={params} />
        )}
        limitTags={5} // 最大表示数を5件に制限
      />
    </>
  );
};
