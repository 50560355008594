// リダイレクト
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Checkbox,
  Card,
  Button,
  FormControlLabel,
  Grid,
  Link,
} from "@mui/material";
import { borderColor, styled } from "@mui/system";
import { palette, useTheme } from "Theme";
import { Main as Modal } from "atoms/Modal";
import { tProject, tProjectCardList } from "types/project";
import { getProjectCardList } from "functions/api/project";
import { CommonDataContext } from "Context";
import { typeHash } from "types";
import {
  isSameDate,
  MathDateTime,
  getPreviousMonday,
  getCharWeekday,
} from "functions/time";
import { initialProjectKind, tProjectKind } from "types/mst";
import { ClientCompany } from "components/project/Index";
import { RangeLoadDatetime } from "components/project/Search";
import { PrimaryButton, LinkPrimaryButton } from "atoms/Button";
import { truncateString } from "functions/index";

interface searchProps {
  status: number[] | null;
  load_date_from: string;
  load_date_to: string;
  c_id: number | null;
}

const Container = styled("div")({
  "& .head": {
    backgroundColor: "#f5f5f5",
    fontWeight: "bold",
    //border: "1px solid #e0e0e0",
    position: "sticky",
    top: 0,
    zIndex: 95,
    "& .cell": {
      textAlign: "center",
      "& .MuiFormControlLabel-root": {
        margin: "auto",
      },
      "&.day": {
        position: "sticky",
        left: 0,
        zIndex: 99,
        margin: "none",
        backgroundColor: "inherit",
      },
    },
  },
  "& .cell": {
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
    "&.day": {
      width: "150px",
      //border: "1px solid #e0e0e0",
      textAlign: "center",
    },
    "&.kind": {
      width: "50px",
      //border: "1px solid #e0e0e0",
      "&.active": {
        minWidth: "200px",
        borderLeft: "1px solid e0e0e0",
        borderRight: "1px solid e0e0e0",
      },
      "& .transport": {
        //border: "1px solid #e0e0e0",
        margin: 4,
        "& *": {
          fontSize: "0.6rem",
        },
        "&.evening": {
          border: "2px solid red",
        },
      },
    },
  },

  "& .row": {
    display: "inline-flex",
    border: "1px solid black",
    minWidth: "100%",
  },

  "& .row.data": {
    "&.Monday": {
      ".kind.odd": { backgroundColor: "rgba(255, 211, 44, 0.3)" },
      ".kind.even": { backgroundColor: "rgba(255, 211, 44, 0.6)" },
    },
    "&.Tuesday": {
      ".kind.odd": { backgroundColor: "rgba(240, 107, 68, 0.3)" },
      ".kind.even": { backgroundColor: "rgba(240, 107, 68, 0.6)" },
    },
    "&.Wednesday": {
      ".kind.odd": { backgroundColor: "rgba(93, 127, 204, 0.3)" },
      ".kind.even": { backgroundColor: "rgba(93, 127, 204, 0.6)" },
    },
    "&.Thursday": {
      ".kind.odd": { backgroundColor: "rgba(93, 170, 102, 0.3)" },
      ".kind.even": { backgroundColor: "rgba(93, 170, 102, 0.6)" },
    },
    "&.Friday": {
      ".kind.odd": { backgroundColor: "rgba(204, 170, 51, 0.3)" },
      ".kind.even": { backgroundColor: "rgba(204, 170, 51, 0.6)" },
    },
    "&.Saturday": {
      ".kind.odd": { backgroundColor: "rgba(139, 103, 61, 0.3)" },
      ".kind.even": { backgroundColor: "rgba(139, 103, 61, 0.6)" },
    },
    "&.Sunday": {
      ".kind.odd": { backgroundColor: "rgba(255, 135, 63, 0.3)" },
      ".kind.even": { backgroundColor: "rgba(255, 135, 63, 0.6)" },
    },
    "& .cell.day": {
      backgroundColor: "#f5f5f5",
      display: "flex",
      justifyItems: "center",
      alignItems: "center",
      position: "sticky",
      left: 0,
      zIndex: 91,
      "& *": {
        margin: "auto",
      },
    },
    "& .cell.kind": {
      "& .status-complete": {
        backgroundColor: palette.glayDark,
        color: palette.white,
      },
      "& .status-transport": {
        //backgroundColor: useTheme.palette.primary.main,
      },
      "& .status-no": {
        backgroundColor: palette.white,
      },
    },
  },
});

export default function Main() {
  const navigate = useNavigate();

  const [openSearch, setOpenSearch] = useState(false);
  const [dateProjects, setDateProjects] = useState<
    Record<string, tProjectCardList>
  >({});

  const { loading, projectKinds } = useContext(CommonDataContext);
  const [viewPK, setViewPK] = useState<tProjectKind[]>([]);
  const [checkedKind, setCheckedKind] = useState<Record<number, boolean>>({});
  const [search, setSearch] = useState<searchProps>({
    status: [10, 50],
    load_date_from: getPreviousMonday(new Date().toISOString())
      .toISOString()
      .split("T")[0],
    load_date_to: MathDateTime(new Date(), [0, 0, 7, 0, 0, 0])
      .toISOString()
      .split("T")[0],
    c_id: null,
  });

  // 検索処理
  const [viewDate, setViewDate] = useState<Record<string, typeHash>>({});
  const searchProject = async () => {
    const res = await getProjectCardList(search);
    // 対象の日付を取得
    const tmpViewDate = getWeekDatesWithDays(
      search.load_date_from,
      search.load_date_to
    );

    const targetData = res.data.target; // 検索条件に一致するもの
    /** memo：表示対象以前のデータについて
     * 現在(2024/07/24)だと案件が完了するタイミングがないため、
     * 検索対象以前のデータの表示はしない
     */
    //const beforeData = res.data.before; // 検索対象より前

    // データを格納
    setDateProjects((prev) => {
      let temp: Record<string, tProjectCardList> = {};
      Object.keys(tmpViewDate).forEach((date) => {
        // 1日分のデータを取得
        const dateProjects = targetData.filter((project: tProject) => {
          return isSameDate(project.load_date, date);
        });
        temp[date] = dateProjects;
      });
      return {
        ...temp,
      };
    });

    setViewDate(tmpViewDate);
  };

  useEffect(() => {
    searchProject();
  }, []);

  useEffect(() => {
    if (!projectKinds) {
      return;
    }

    const newPK = [...projectKinds, initialProjectKind];
    const tmpCheck: Record<number, boolean> = {};
    newPK.map((kind) => {
      tmpCheck[Number(kind.id)] = true;
    });

    setViewPK(newPK);
    setCheckedKind(tmpCheck);
  }, [projectKinds]);

  if (loading || !projectKinds) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <Modal
        title={"検索"}
        content={<ModalSearch search={search} setSearch={setSearch} />}
        actions={
          <>
            <Button
              onClick={() => {
                searchProject();
                setOpenSearch(false);
              }}
            >
              検索
            </Button>
            <Button onClick={() => setOpenSearch(false)}>閉じる</Button>

            <LinkPrimaryButton label="TOPへ" href={"/"} />
          </>
        }
        open={openSearch}
      />
      <div className="row head">
        <div className="cell day">
          <Button onClick={() => setOpenSearch(true)}>検索</Button>
        </div>
        {viewPK.map((projectKind, index) => (
          <div
            className={`cell kind ${
              checkedKind[projectKind.id] ? "active" : ""
            }`}
          >
            <Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked
                    onChange={(e) =>
                      setCheckedKind({
                        ...checkedKind,
                        [projectKind.id]: e.target.checked,
                      })
                    }
                    value={checkedKind[Number(projectKind.id)]}
                  />
                }
                label={
                  checkedKind[Number(projectKind.id)] ? projectKind.name : ""
                }
              />
            </Typography>
          </div>
        ))}
      </div>

      {Object.keys(dateProjects).map((date, dIndex) => (
        <div
          className={`row data ${viewDate[date].weekday}`}
          key={`schedule-${date}`}
          style={{ minHeight: "75px" }}
        >
          <div className="cell day">
            <Link href={`/project/input?date=${date}`}>
              <Typography>{`${date}(${getCharWeekday(date)})`}</Typography>
            </Link>
          </div>

          {viewPK?.map((kind, index) => {
            const categoryProject = dateProjects[date].filter((project) => {
              return project.kind === kind.id;
            });

            return (
              <div
                className={`cell kind ${
                  checkedKind[Number(kind.id)] ? "active" : ""
                } ${index % 2 === 0 ? "even" : "odd"}`}
              >
                <Grid container>
                  {categoryProject.map((project, i) => {
                    const eveningLoad = isSameDate(
                      project.load_date,
                      project.unload_date
                    );

                    const torisakiName = project.company_abbreviation
                      ? `${project.company_abbreviation}`
                      : project.company_name
                      ? project.company_name
                      : "(新規)";

                    const loadName = project.load_abbreviation_mst
                      ? project.load_abbreviation_mst
                      : project.load_name_mst
                      ? project.load_name_mst
                      : project.load_name;

                    const unloadName = project.unload_abbreviation_mst
                      ? project.unload_abbreviation_mst
                      : project.unload_name_mst
                      ? project.unload_name_mst
                      : project.unload_name;

                    return (
                      <Grid item xs={6}>
                        <Card
                          key={`schedule-${date}-${project.id}-${i}`}
                          className={`transport status-${project.status} ${
                            eveningLoad ? "evening" : ""
                          }`}
                          style={{
                            padding: "0.25rem" /*backgroundColor: kind.color*/,
                          }}
                          onDoubleClick={() =>
                            navigate(`/project/edit/${project.id}`)
                          }
                        >
                          {checkedKind[Number(kind.id)] ? (
                            <>
                              <Link
                                href={`/project/edit/${project.id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Typography>
                                  {truncateString(torisakiName, 6)}
                                </Typography>
                              </Link>
                              <Typography>
                                <li>{truncateString(loadName, 6)}</li>
                                <li>{truncateString(unloadName, 6)}</li>
                              </Typography>
                            </>
                          ) : (
                            ""
                          )}
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            );
          })}
        </div>
      ))}
    </Container>
  );
}

type tViewDate = Record<string, typeHash>;

function getWeekDatesWithDays(startDate: string, endDate: string): tViewDate {
  const daysOfWeek: string[] = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  let weekDates: tViewDate = {};

  // startDateからendDateまでの日付を取得
  let currentDate = new Date(startDate);
  const finalDate = new Date(endDate);

  while (currentDate <= finalDate) {
    // 日付をキーに曜日を値とするオブジェクトを作成
    const dateKey: string = currentDate.toISOString().split("T")[0];
    const dayOfWeek: string = daysOfWeek[currentDate.getDay()];

    weekDates[dateKey] = { weekday: dayOfWeek };

    // １日加算
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return weekDates;
}

function ModalSearch({
  search,
  setSearch,
}: {
  search: searchProps;
  setSearch: React.Dispatch<React.SetStateAction<searchProps>>;
}) {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <RangeLoadDatetime values={search} setValues={setSearch} />
      </Grid>
      <ClientCompany
        values={search}
        setValues={setSearch}
        size={{ xs: 12, lg: 4 }}
      />
      {/*
      <Status values={search} setValues={setSearch} size={{ xs: 12, lg: 4 }} />
      */}
      {/*
      <Status values={search} setValues={setSearch} size={{ xs: 12, lg: 4 }} />
      */}
    </Grid>
  );
}
