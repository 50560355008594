import { CheckboxProps } from "@mui/material/Checkbox";
import { FormControlLabel, Checkbox } from "@mui/material";

interface SmallTextFieldProps {
  label?: string;
  labelId?: string;
  props: CheckboxProps; // ここでpropsを定義します
}

export const SmallCheckbox: React.FC<SmallTextFieldProps> = ({
  label = "",
  labelId = "",
  props,
}) => {
  return (
    <FormControlLabel
      control={<Checkbox size="small" {...props} />}
      label={label}
      id={labelId}
    />
  );
};
