import React, { useEffect, useState, useContext } from "react";
import { Grid, Typography } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  SmallTextField,
  TableCellTextField,
  AutocompleteTextField,
} from "atoms/TextField";
import { styled } from "@mui/system";
import { AutocompleteSelect } from "atoms/Select";
import { storeAddress } from "functions/api/mst";
import { List, ListItem, typeHashValue } from "types/index";
import { tWaypoints, initialWaypoint } from "types/project";
import { tAddress, initialAddress } from "types/mst";
import { CommonDataContext } from "Context";
import { projectKbn } from "const/project/index";
import { watpointKbn, prefectures } from "const/index";
import { changeFromDatetimeToInputTypeDate } from "functions/time";
import { PrimaryButton, PlusButton, MinusButton } from "atoms/Button";
import { getTargetAddress } from "functions/mst/address";
import { DateInput } from "atoms/DateInput";

const LoadTableContainer = styled(TableContainer)({
  "& .kbn": {
    width: "5%",
    minWidth: "120px",
  },
  "& .datetime": {
    width: "15%",
    minWidth: "200px",
  },
  "& .a_id": {
    width: "20%",
    minWidth: "200px",
  },
  "& .prefectures": {
    width: "8%",
    minWidth: "140px",
  },
  "& .city": {
    width: "8%",
    minWidth: "150px",
  },
  "& .street": {
    width: "12%",
    minWidth: "170px",
  },
  "& .building": {
    width: "12%",
    minWidth: "170px",
  },
  "& .memo": {
    width: "12%",
    minWidth: "150px",
  },
  "& .proc": {
    width: "12%",
    minWidth: "50px",
  },
});

interface WaypointTableProps {
  label: string;
  values: tWaypoints;
  setValues: React.Dispatch<React.SetStateAction<tWaypoints>>;
}

/**
 * 積地と卸地のテーブル
 * @param label
 * @param columnHeads
 * @param rowHeads
 * @param transport
 * @param setTransport
 * @returns
 */
export function WaypointsTable({
  label,
  values,
  setValues,
}: WaypointTableProps) {
  const addRow = (index: number) => {
    // indexの位置に新しいオブジェクトを追加
    setValues((prev) => {
      const temp = initialWaypoint;
      const newRow = [...prev];
      newRow.splice(index + 1, 0, { ...temp });
      return newRow;
    });
  };

  const removeRow = (index: number) => {
    // indexの位置のオブジェクトを削除
    setValues((prev) => {
      const newRow = [...prev];
      newRow.splice(index, 1);
      return newRow;
    });
  };

  /**
   * テーブル型の入力フォームの変更を処理する関数
   * @param value
   * @param rowValues
   * @param setState
   * @param field
   */
  const handleWaypointsChange = (
    value: typeHashValue,
    rowField: number,
    columnField: string,
    setState: React.Dispatch<React.SetStateAction<tWaypoints>>
  ) => {
    setState((prev) => {
      // 既存のrowField配列をコピー
      const newRowField = [...prev];

      // 指定されたインデックスのオブジェクトをコピーして更新
      newRowField[rowField] = {
        ...newRowField[rowField],
        [columnField]: value,
      };

      // 新しい状態を返す
      return newRowField;
    });
  };

  const rowData = (
    index: number,
    values: tWaypoints,
    setValues: React.Dispatch<React.SetStateAction<tWaypoints>>
  ) => {
    return (
      <TableRow key={`project-waypoints-${index}`}>
        <TableCell>
          <Grid container spacing={4} sx={{ textAlign: "center" }}>
            {values.length > 2 &&
              index !== 0 &&
              index !== values.length - 1 && (
                <Grid item xs={12}>
                  <MinusButton
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      removeRow(index);
                    }}
                    props={{
                      sx: { minHeight: "unset", height: "15px", width: "15px" },
                    }}
                  />
                </Grid>
              )}

            {index !== values.length - 1 && (
              <Grid item xs={12}>
                <PlusButton
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    addRow(index);
                  }}
                  props={{
                    sx: { minHeight: "unset", height: "15px", width: "15px" },
                  }}
                />
              </Grid>
            )}
          </Grid>
        </TableCell>
        <TableCell>
          <Kbn
            index={index}
            options={watpointKbn}
            values={values}
            setValues={setValues}
            onChange={handleWaypointsChange}
          />
        </TableCell>
        <TableCell>
          <Datetime
            index={index}
            values={values}
            setValues={setValues}
            onChange={handleWaypointsChange}
          />
        </TableCell>
        <TableCell>
          <Address
            index={index}
            values={values}
            setValues={setValues}
            onChange={handleWaypointsChange}
          />
        </TableCell>
        <TableCell>
          <Prefectures
            index={index}
            values={values}
            setValues={setValues}
            onChange={handleWaypointsChange}
          />
        </TableCell>
        <TableCell>
          <City
            index={index}
            values={values}
            setValues={setValues}
            onChange={handleWaypointsChange}
          />
        </TableCell>
        <TableCell>
          <Street
            index={index}
            values={values}
            setValues={setValues}
            onChange={handleWaypointsChange}
          />
        </TableCell>
        <TableCell>
          <Building
            index={index}
            values={values}
            setValues={setValues}
            onChange={handleWaypointsChange}
          />
        </TableCell>
        <TableCell>
          <WaypointMemo
            index={index}
            values={values}
            setValues={setValues}
            onChange={handleWaypointsChange}
          />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Grid item xs={12}>
      <LoadTableContainer>
        <Typography className="label" variant="h6">
          {label}
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="button"></TableCell>
              <TableCell className="kbn">区分</TableCell>
              <TableCell className="date">日付</TableCell>
              <TableCell className="a_id">名称</TableCell>
              <TableCell className="prefectures">都道府県</TableCell>
              <TableCell className="city">市区町村</TableCell>
              <TableCell className="street">番地</TableCell>
              <TableCell className="building">建屋・その他</TableCell>
              <TableCell className="memo">メモ</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {values.map((value, index) => rowData(index, values, setValues))}
          </TableBody>
        </Table>
      </LoadTableContainer>
    </Grid>
  );
}

interface TableItemSelectProps {
  index: number;
  options: List;
  values: tWaypoints;
  setValues: React.Dispatch<React.SetStateAction<tWaypoints>>;
  onChange: (
    value: typeHashValue,
    rowField: number,
    columnField: string,
    setState: React.Dispatch<React.SetStateAction<tWaypoints>>
  ) => void;
}

const Kbn = ({
  index,
  options,
  values,
  setValues,
  onChange,
}: TableItemSelectProps) => {
  const selectedOption = options.find(
    (option) => option.id === Number(values[index].kbn)
  );

  const flgReadOnly = index === 0 || values.length === index + 1 ? true : false;

  if (flgReadOnly) {
    return <Typography>{selectedOption?.label}</Typography>;
  } else {
    return (
      <AutocompleteSelect
        name={"waypoints." + index + ".kbn"}
        label={""}
        options={options}
        value={selectedOption ?? null}
        onChange={(e: React.ChangeEvent<{}>, newValue: ListItem) =>
          onChange(Number(newValue.id), index, "kbn", setValues)
        }
      />
    );
  }
};

interface TableItemAddressProps {
  index: number;
  values: tWaypoints;
  setValues: React.Dispatch<React.SetStateAction<tWaypoints>>;
  onChange: (
    value: typeHashValue,
    rowField: number,
    columnField: string,
    setState: React.Dispatch<React.SetStateAction<tWaypoints>>
  ) => void;
}
const Address = ({
  index,
  values,
  setValues,
  onChange,
}: TableItemAddressProps) => {
  const { addresses, loading, fetchData } = useContext(CommonDataContext);
  const [options, setOptions] = useState<List>([]);

  useEffect(() => {
    if (addresses) {
      const flgLoad = values[index].kbn === projectKbn.load.id ? true : false;
      const flgUnload =
        values[index].kbn === projectKbn.unload.id ? true : false;
      const temp = getTargetAddress(addresses, false, flgLoad, flgUnload);
      setOptions(
        temp.map((address) => ({
          id: address.id,
          label: address.name,
          key: address.id,
        }))
      );
    }
  }, [addresses, values[index].kbn]);

  if (loading || addresses === null) {
    return <div>Loading...</div>;
  }
  const selectedOption = addresses.find(
    (address: tAddress) => address.id === values[index].a_id
  );

  return (
    <div style={{ display: "flex" }}>
      <AutocompleteTextField
        name={"waypoints." + index + ".a_id"}
        freeSolo={true}
        label={""}
        options={options}
        value={
          selectedOption
            ? { id: selectedOption.id, label: values[index].a_name }
            : null
        }
        inputValue={values[index].a_name}
        onChange={(e: React.ChangeEvent<{}>, newValue: ListItem) => {
          // クリアボタン押下した時用
          if (newValue === null) {
            onChange(undefined, index, "a_id", setValues);
            onChange(undefined, index, "a_name", setValues);
            onChange("", index, "prefectures", setValues);
            onChange("", index, "city", setValues);
            onChange("", index, "street", setValues);
            onChange("", index, "building", setValues);
            return;
          }

          const selectedOption = addresses.find((address: tAddress) =>
            address.name === newValue.label ? newValue.label : ""
          );

          if (typeof newValue === "string") {
            onChange(
              selectedOption ? selectedOption.id : 0,
              index,
              "a_id",
              setValues
            );
            onChange(newValue, index, "a_name", setValues);
          } else {
            onChange(newValue.id, index, "a_id", setValues);
            onChange(newValue.label, index, "a_name", setValues);
          }

          // 選択が変わったら
          if (selectedOption) {
            onChange(
              selectedOption.prefectures,
              index,
              "prefectures",
              setValues
            );
            onChange(selectedOption.city, index, "city", setValues);
            onChange(selectedOption.street, index, "street", setValues);
            onChange(
              selectedOption.building ? selectedOption.building : "",
              index,
              "building",
              setValues
            );
          }
        }}
      />
      {values[index].a_id ? (
        <></>
      ) : (
        <PrimaryButton
          label={"登録"}
          onClick={() => {
            let address: tAddress = initialAddress;
            address.name = values[index].a_name;
            address.prefectures = values[index].prefectures;
            address.city = values[index].city;
            address.building = values[index].building;
            if (values[index].kbn === projectKbn.load.id) {
              address.flg_load = true;
            } else if (values[index].kbn === projectKbn.unload.id) {
              address.flg_unload = true;
            }
            storeAddress(address)
              .then((res) => {
                if (res.status !== 200) {
                  alert(`データ登録に失敗しました。[${res.status}]`);
                }
                fetchData();
                onChange(res.data.id, index, "a_id", setValues);
                onChange(res.data.name, index, "a_name", setValues);
                alert("データを登録しました");
              })
              .catch((err) => {
                const message = err.response.data.message;
                alert(
                  `データ登録に失敗しました。[${err.status}]\n\n${message}`
                );
                console.error(err);
              });
          }}
        />
      )}
    </div>
  );
};

interface TableItemTextProps {
  index: number;
  values: tWaypoints;
  setValues: React.Dispatch<React.SetStateAction<tWaypoints>>;
  onChange: (
    value: typeHashValue,
    rowField: number,
    columnField: string,
    setState: React.Dispatch<React.SetStateAction<tWaypoints>>
  ) => void;
}

const Datetime = ({
  index,
  values,
  setValues,
  onChange,
}: TableItemTextProps) => {
  const handleDateChange = (newDate: string) => {
    console.log("newDate", newDate);
    // 入力日の23:59:59に設定
    const inputDateTime = new Date(newDate + "T23:59:59");
    if (inputDateTime.toString() === "Invalid Date") {
      return;
    }
    onChange(inputDateTime.toISOString(), index, "datetime", setValues);
  };
  return (
    <DateInput
      type="date"
      value={changeFromDatetimeToInputTypeDate(
        new Date(values[index].datetime)
      )}
      name="myDate"
      onDateChange={handleDateChange}
      showSpinButtons={true} // スピンボタンのオンオフを制御
    />
    /*
    <TableCellTextField
      props={{
        type: "date",
        name: "waypoints." + index + ".datatime",
        value: changeFromDatetimeToInputTypeDate(
          new Date(values[index].datetime)
        ),
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          // 入力日の23:59:59に設定
          // 入力日の23:59:59に設定
          const inputDate = e.target.value;
          const inputDateTime = new Date(inputDate + "T23:59:59");
          if (inputDateTime.toString() === "Invalid Date") {
            return;
          }
          onChange(inputDateTime.toISOString(), index, "datetime", setValues);
        },
      }}
    />
    */
  );
};

const Prefectures = ({
  index,
  values,
  setValues,
  onChange,
}: TableItemTextProps) => {
  const options: List = prefectures;
  const name = "waypoints." + index + ".prefectures";

  const selectedOption = options.find(
    (option: ListItem) => option.label === values[index]["prefectures"]
  );

  // 住所マスタ選択判定
  const flgMstData = Number(values[index].a_id) ? true : false;

  return (
    <AutocompleteSelect
      disableClearable={false}
      readOnly={flgMstData}
      name={name}
      label={""}
      options={options}
      value={selectedOption ? selectedOption : null}
      onChange={(e: React.ChangeEvent<{}>, newValue: ListItem) => {
        if (!newValue) {
          onChange("", index, "prefectures", setValues);
          return;
        }
        onChange(newValue.label, index, "prefectures", setValues);
      }}
    />
    /*
      <TableCellTextField
        props={{
          name: "waypoints." + index + ".prefectures",
          value: values[index]["prefectures"],
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            onChange(e.target.value, index, "prefectures", setValues);
          },
        }}
      />
      */
  );
};

const City = ({ index, values, setValues, onChange }: TableItemTextProps) => {
  // 住所マスタ選択判定
  const flgMstData = Number(values[index]?.a_id) ? true : false;

  // 値がundefinedの場合は空文字列を設定
  const cityValue = values[index]?.city ?? "";

  return (
    <TableCellTextField
      props={{
        InputProps: { readOnly: flgMstData },
        name: "waypoints." + index + ".city",
        value: cityValue,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          onChange(e.target.value, index, "city", setValues);
        },
      }}
    />
  );
};

const Street = ({ index, values, setValues, onChange }: TableItemTextProps) => {
  // 住所マスタ選択判定
  const flgMstData = Number(values[index]?.a_id) ? true : false;

  // 値がundefinedの場合は空文字列を設定
  const streetValue = values[index]?.street ?? "";

  return (
    <TableCellTextField
      props={{
        InputProps: { readOnly: flgMstData },
        name: "waypoints." + index + ".street",
        value: streetValue,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          onChange(e.target.value, index, "street", setValues);
        },
      }}
    />
  );
};

const Building = ({
  index,
  values,
  setValues,
  onChange,
}: TableItemTextProps) => {
  // 住所マスタ選択判定
  const flgMstData = Number(values[index]?.a_id) ? true : false;

  // 値がundefinedの場合は空文字列を設定
  const buildingValue = values[index]?.building ?? "";

  return (
    <TableCellTextField
      props={{
        InputProps: { readOnly: flgMstData },
        name: "waypoints." + index + ".building",
        value: buildingValue,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          onChange(e.target.value, index, "building", setValues);
        },
      }}
    />
  );
};

const WaypointMemo = ({
  index,
  values,
  setValues,
  onChange,
}: TableItemTextProps) => {
  // 値がundefinedの場合は空文字列を設定
  const buildingValue = values[index]?.memo ?? "";

  return (
    <TableCellTextField
      props={{
        InputProps: { readOnly: false },
        name: "waypoints." + index + ".memo",
        value: buildingValue,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          onChange(e.target.value, index, "memo", setValues);
        },
      }}
    />
  );
};
