import React, { useContext, useEffect } from "react";
import { AutocompleteTextField } from "atoms/TextField";
import { GridItemTextFieldCustom } from "components/GridItem";
import { CommonDataContext } from "Context";
import { initialAddress, tAddress } from "types/mst";
import { List, ListItem } from "types/index";
import * as cCommon from "components/Common";
import { SmallCheckbox } from "atoms/Checkbox";
import { prefectures } from "const/index";
import { getTargetAddress } from "functions/mst/address";

interface GridItemFieldProps {
  values: tAddress;
  setValues: React.Dispatch<React.SetStateAction<tAddress>>;
  flg_office?: boolean;
  flg_load?: boolean;
  flg_unload?: boolean;
  size?: Record<string, number> | null;
  freeSolo?: boolean;
}

export const Address = ({
  values,
  setValues,
  flg_load,
  flg_unload,
  flg_office,
  freeSolo = true,
  size,
}: GridItemFieldProps) => {
  const { addresses, loading } = useContext(CommonDataContext);
  const [options, setOptions] = React.useState<ListItem[]>([]);

  console.log("values", values);

  useEffect(() => {
    if (addresses) {
      const ad = getTargetAddress(addresses, flg_office, flg_load, flg_unload);

      if (ad) {
        setOptions([
          ...ad.map((address: tAddress) => {
            return {
              id: address.id,
              label: address.name,
              key: address.id, // ここでkeyを追加
            };
          }),
        ]);
      } else {
        setOptions([]);
      }
    }
  }, [addresses, flg_office, flg_load, flg_unload]);

  if (loading || addresses === null) {
    return <div>Loading...</div>;
  }

  return (
    <GridItemTextFieldCustom size={size}>
      <AutocompleteTextField
        name={"id"}
        freeSolo={freeSolo}
        label={"住所マスタ"}
        options={options}
        value={values.id === 0 ? null : { id: values.id, label: values.name }}
        inputValue={values.name}
        onChange={(e: React.ChangeEvent<{}>, newValue: ListItem) => {
          console.log("newValue", newValue);
          // クリアボタン押下した時用
          if (newValue === null) {
            setValues(initialAddress);
            return;
          }

          const selectedOption = addresses.find((address: tAddress) =>
            address.name === newValue.label ? newValue.label : ""
          );

          let id = 0;
          let name = "";
          if (typeof newValue !== "string") {
            id = newValue.id;
            name = newValue.label;
          }

          // 選択が変わったら
          if (selectedOption) {
            setValues((prev) => {
              return {
                ...prev,
                id: newValue.id,
                name: newValue.label,
                prefectures: selectedOption.prefectures,
                city: selectedOption.city,
                street: selectedOption.street,
                building: selectedOption.building,
              };
            });
          } else {
            setValues((prev) => {
              return {
                ...initialAddress,
                id: id,
                name: name,
                flg_load: flg_load ? true : false,
                flg_unload: flg_unload ? true : false,
                flg_office: flg_office ? true : false,
              };
            });
          }
        }}
      />
    </GridItemTextFieldCustom>
  );
};

export const AddressList = ({
  values,
  setValues,
  flg_office = false,
  flg_load = false,
  flg_unload = false,
  size = null,
}: {
  values: tAddress;
  setValues: React.Dispatch<React.SetStateAction<tAddress>>;
  flg_office?: boolean;
  flg_load?: boolean;
  flg_unload?: boolean;
  size?: Record<string, number> | null;
}) => {
  const { loading, addresses } = useContext(CommonDataContext);
  const [options, setOptions] = React.useState<ListItem[]>([]);

  useEffect(() => {
    if (values.id !== 0) {
      const temp = addresses?.find(
        (address: tAddress) => address.id === values.id
      );
      if (temp) {
        setValues(temp);
      } else {
        setValues(initialAddress);
      }
    } else {
      setValues(initialAddress);
    }
  }, [values.id]);

  useEffect(() => {
    if (addresses) {
      const ad = getTargetAddress(addresses, flg_office, flg_load, flg_unload);

      if (ad) {
        setOptions([
          { id: 0, label: "(未登録)" },
          ...ad.map((address: tAddress) => {
            return {
              id: address.id,
              label: address.name,
            };
          }),
        ]);
      } else {
        setOptions([{ id: 0, label: "" }]);
      }
    }
  }, [addresses, flg_office, flg_load, flg_unload]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <cCommon.ItemSelect
      name="id"
      value={values.id}
      labelId="id-label"
      id="id"
      label="住所名"
      selectedValue={Number(values.id)}
      items={options}
      setValues={setValues}
      size={size}
    />
  );
};

export const Name = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="名称"
    value={values.name}
    name="name"
    setValues={setValues}
  />
);

export const Prefectures = ({ values, setValues }: GridItemFieldProps) => {
  const [selected, setSelected] = React.useState<any>({ prefectures: "" });

  const options: List = [{ id: 0, label: "" }, ...prefectures];

  useEffect(() => {
    const temp = options.find((option: ListItem) => {
      return option.id === selected.prefectures;
    });

    let prefectureName = "";
    if (temp !== undefined) {
      prefectureName = temp.label;
    }

    setValues((prev) => {
      return {
        ...prev,
        prefectures: prefectureName,
      };
    });
  }, [selected]);

  return (
    <cCommon.ItemSelect
      name="prefectures"
      value={selected.prefectures}
      labelId="prefectures-label"
      id="prefectures"
      label="都道府県"
      selectedValue={selected.prefectures}
      items={options}
      setValues={setSelected}
    />
  );
};

export const City = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="市区町村"
    value={values.city}
    name="city"
    setValues={setValues}
  />
);

export const Street = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="番地"
    value={values.street}
    name="street"
    setValues={setValues}
  />
);

export const Building = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="建屋・その他"
    value={values.building}
    name="building"
    setValues={setValues}
  />
);
