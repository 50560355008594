import React from "react";
import { tCompany } from "types/mst";
import * as cCommon from "components/Common";

interface GridItemFieldProps {
  values: tCompany;
  setValues: React.Dispatch<React.SetStateAction<tCompany>>;
}
export const Name = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="会社名"
    name="name"
    value={values.name}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const Abbreviation = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="略称"
    name="abbreviation"
    value={values.abbreviation}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const Tell = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="電話番号"
    name="tell"
    value={values.tell}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const Fax = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="FAX"
    name="fax"
    value={values.fax}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const EMail = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="メールアドレス"
    name="e_mail"
    value={values.e_mail}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const PresidentPosition = ({
  values,
  setValues,
}: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="代表役職"
    name="president_position"
    value={values.president_position}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const PresidentName = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="代表者名"
    name="president_name"
    value={values.president_name}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const FlgUse = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="用者利用"
    name="flg_use"
    value={values.flg_use}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const FlgOrder = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="受注元"
    name="flg_order"
    value={values.flg_order}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const FlgSupplier = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="発注先"
    name="flg_supplier"
    value={values.flg_supplier}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);
