import React from "react";
import { Console_log } from "functions/index";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomeLayout from "layouts/HomeFrame";
import MainLayout from "layouts/MainFrame";
import LogginLayout from "layouts/LoginFrame";
import Top from "pages/Index";
import Login from "pages/Login";
import Logout from "pages/Logout";
import { menus } from "const/menu";
import { CommonDataProvider } from "./Context";
import FSSchedules from "pages/full-screen/Schedules";
import ProtectedRoute from "components/ProtectedRoute";

////////////////

// トップDomを取得
const rootDom = document.getElementById("root");

if (rootDom) {
  const root = ReactDOM.createRoot(rootDom);

  root.render(
    <React.StrictMode>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
        </Routes>
        <CommonDataProvider>
          <Routes>
            <Route element={<ProtectedRoute />}>
              {menus.map((menu) => {
                return (
                  <React.Fragment key={menu.path}>
                    <Route path="/" element={<HomeLayout />}>
                      <Route path="/" element={<Top />} />
                    </Route>
                    <Route
                      path="/full-screen/schedules"
                      element={<FSSchedules />}
                    />
                    <Route
                      key={`${menu.path}-root`}
                      element={<MainLayout key={menu.path} menu={menu} />}
                    >
                      <Route
                        key={`${menu.path}`}
                        path={`${menu.path}`}
                        element={menu.element}
                      />
                      {menu.subMenus.map((subMenu) => {
                        return (
                          <Route
                            key={`${menu.path}-${subMenu.path}`}
                            path={`${subMenu.path}`}
                            element={subMenu.element}
                          />
                        );
                      })}
                    </Route>
                  </React.Fragment>
                );
              })}
              ;
            </Route>
          </Routes>
        </CommonDataProvider>
      </Router>
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
