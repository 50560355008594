import { Button, Box } from "@mui/material";
import { styled } from "@mui/system";
import { useTheme } from "Theme";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Link from "@mui/material/Link";

export const ButtonContainer = styled(Box)((theme) => ({
  display: "flex",
  justifyContent: "right",
  gap: useTheme.spacing(4),
  marginTop: useTheme.spacing(4),
  "& button": {
    minWidth: "120px",
  },
}));

interface PrimaryButtonProps {
  onClick?: () => void;
  label: string;
  href?: string;
  aTab?: boolean;
}

export function PrimaryButton({
  onClick,
  label,
  ...props
}: PrimaryButtonProps) {
  return (
    <Button variant="contained" color="primary" onClick={onClick}>
      {label}
    </Button>
  );
}

export function SecondButton({ onClick, label, ...props }: PrimaryButtonProps) {
  return <Button onClick={onClick}>{label}</Button>;
}

export function PlusButton({
  onClick,
  props,
}: {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  props?: any;
}) {
  return (
    <Fab color="primary" onClick={onClick} {...props}>
      <AddIcon />
    </Fab>
  );
}

export function MinusButton({
  onClick,
  props,
}: {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  props?: any;
}) {
  return (
    <Fab color="secondary" onClick={onClick} {...props}>
      <RemoveIcon />
    </Fab>
  );
}

export function LinkPrimaryButton({
  href,
  aTab = false,
  label,
}: PrimaryButtonProps) {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (aTab && href) {
      e.preventDefault();
      window.location.href = href;
    }
  };

  return (
    <Button
      variant="contained"
      component={Link}
      color="primary"
      href={href}
      onClick={handleClick}
    >
      {label}
    </Button>
  );
}
